import styled from 'styled-components'

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const Item = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;

  &:last-child {
    /* margin-bottom: 0; */
  }
`

export const ContactLink = styled.span`
  min-width: 180px;
`
