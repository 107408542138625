import React from 'react'
import { List, Item, ContactLink } from './styles'
import Contact from '../shared/Contact'

const ContactsSection = ({ list }) => {
  return (
    <List>
      {list.map(({ name, value, type }) => (
        <Item key={value}>
          <ContactLink>
            <Contact type={type} value={value} />
          </ContactLink>
          {name}
        </Item>
      ))}
    </List>
  )
}

export default ContactsSection
