import React from 'react'
import Layout from '../components/Layout'
import Section from '../components/shared/Section'
import Container from '../components/shared/Container'
import SectionTitle from '../components/shared/SectionTitle'
import useContactsPageQuery from '../hooks/useContactsPageQuery'
import ContactsSection from '../components/ContactsSection'
import Contacts from '../components/Contacts'
import Socials from '../components/Socials'
import SEO from '../components/Seo/Seo'

export const ContactsPageTemplate = ({
  title,
  description,
  address,
  emails,
  phones,
  socials,
}) => (
  <>
    <Section>
      <Container>
        <SectionTitle>{title}</SectionTitle>
        <p>{description}</p>
        <ContactsSection>
          <h4>{address.title}</h4>
          <Contacts list={address.list} />
        </ContactsSection>
        <ContactsSection type="email" list={emails.list}>
          <h4>{emails.title}</h4>
          <Contacts list={emails.list} />
        </ContactsSection>
        <ContactsSection>
          <h4>{phones.title}</h4>
          <Contacts list={phones.list} />
        </ContactsSection>
        <ContactsSection>
          <h4>{socials.title}</h4>
          <Socials invert socials={socials.list} />
        </ContactsSection>
      </Container>
    </Section>
  </>
)

const ContactsPage = () => {
  const {
    title,
    description,
    schedule,
    address,
    emails,
    phones,
    additionals,
    socials,
  } = useContactsPageQuery()

  return (
    <Layout>
      <SEO />
      <ContactsPageTemplate
        title={title}
        description={description}
        schedule={schedule}
        address={address}
        emails={emails}
        phones={phones}
        additionals={additionals}
        socials={socials}
      />
    </Layout>
  )
}

export default ContactsPage
